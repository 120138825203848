//console.log("global");
jQuery(function($) {
    $("body").on('click', '.nav-trigger', function(e) {
        e.preventDefault();
        $("body").toggleClass('off-canvas-body');
        $("html").toggleClass('off-canvas-overflow');
        $(".main-navigation").toggleClass("off-canvas");
    });

    $('body').on("click", ".main-navigation .menu-item-has-children > a", function(e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).next('.sub-menu').slideToggle(600);
        $(this).parent().toggleClass('arrow-toggle');
    });

    $(document).on("click", function(e) {
        if ($(e.target).closest('.main-navigation').length === 0 && $(e.target).closest('.sm-view.mobile-menu-wrap').length === 0 && !$(e.target).parent().hasClass('nav-trigger')) {
            $("body").removeClass('off-canvas-body');
            $("html").removeClass('off-canvas-overflow');
            $(".main-navigation").removeClass("off-canvas");
        }
    });
	
	/***************************/
    if ($('.aop-link-wrap').length) {
        $('body').on("click", '.aop-link-wrap', function(e) {
			e.preventDefault();
			$(this).closest('.aop-posts-inner-title-wrap').find('.aop-link-wrap').removeClass('active');
			$(this).closest('.aop-posts-inner-title-wrap').find('li').removeClass("active");
			$(this).closest('.aop-posts-inner-title-wrap').find('li').removeClass("remove-border");
			$(this).closest('.aop-accordion-inner-wrap').find('.aop-sin-post-data-wrap').removeClass('active');
			$(this).closest('.aop-accordion-inner-wrap').find('.aop-posts-content-data').find('.aop-posts-inner-title-wrap a').removeClass('active');
			$(this).addClass("active");
			$(this).parent().addClass("active");
			//$(this).parent().prev('li').addClass("remove-border");
			var get_data = $(this).data('click');
			
			$(this).closest('.aop-accordion-inner-wrap').find('.aop-posts-content-data').find('.aop-posts-inner-title-wrap').each(function() {
				if($(this).find('a').data('click') == get_data) {
					$(this).find('a').addClass("active");
					//$(this).prev().prev().find('a').addClass("remove-border");
				}
			});
			
			$(this).closest('.aop-accordion-inner-wrap').find('.aop-sin-post-data-wrap').each(function() {
				if($(this).data('post') == get_data) {
					$(this).addClass("active");
				}
			});
		});
    }
	if ($('.aop-link-wrap').length) {
        $('body').on("click", '.aop-link-wrap.aop-link-wrap-sm', function(e) {
			e.preventDefault();
			//$(this).closest('.aop-posts-inner-title-wrap').next('.aop-sin-post-data-wrap').toggleClass('sm-active');
			var get_data = $(this).data('click');
			
			$(this).closest('.aop-accordion-inner-wrap').find('.aop-sin-post-data-wrap').each(function() {
				if($(this).data('post') == get_data) {
					$(this).toggleClass('sm-active');
				}
				else {
					$(this).removeClass('sm-active');
				}
			});
		});
    }
    /***************************/

    /***************************/
    if ($('.what-clients-slider-wrap.wcslide-init').length) {
        if ($('.what-clients-slider-wrap.wcslide-init').hasClass('slick-initialized')) {
            $('.what-clients-slider-wrap.wcslide-init').slick('unslick');
        }
        $('.what-clients-slider-wrap.wcslide-init').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            prevArrow: '<a class="slick-arrow slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="11.547" height="20.528" viewBox="0 0 11.547 20.528"><path d="M.376,11.171a1.283,1.283,0,0,0,1.814,0L10.264,3.1l8.074,8.074a1.283,1.283,0,1,0,1.814-1.814L11.171.376a1.283,1.283,0,0,0-1.814,0L.376,9.357A1.283,1.283,0,0,0,.376,11.171Z" transform="translate(0 20.528) rotate(-90)" fill-rule="evenodd"/></svg></a>',
            nextArrow: '<a class="slick-arrow slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="11.547" height="20.528" viewBox="0 0 11.547 20.528"><path d="M4.376,8.376a1.283,1.283,0,0,1,1.814,0l8.074,8.074,8.074-8.074a1.283,1.283,0,0,1,1.814,1.814l-8.981,8.981a1.283,1.283,0,0,1-1.814,0L4.376,10.19A1.283,1.283,0,0,1,4.376,8.376Z" transform="translate(-8 24.528) rotate(-90)" fill-rule="evenodd"/></svg></a>',
        });
    }
    /***************************/

    $('body').on("click", '.sin-que-wrap', function(e) {
        e.preventDefault();
        $(this).closest('.sin-que-ans-main-wrap').find('.sin-ans-wrap').slideToggle(600);
        $(this).toggleClass("open");
    });
	
	$('body').on("click", '.foot-contact-form-title', function(e) {
        e.preventDefault();
        $(this).closest('.site-contact-inner-container').find('.foot-contact-form-data').slideToggle(600);
        $(this).toggleClass("open");
    });
});